import { Const } from '../lib/const.cls'

export const mutations = {
    [Const.SET_TOP_GAINERS](state, data) {
        try { state.topGainers = data } catch (ex) { console.debug('set state EX', ex) }
    },
    [Const.SET_FAQS_LIST](state, data) {
        try { state.faqslist = data } catch (ex) { console.debug('set state EX', ex) }
    },
    [Const.SET_HOOPS_DATA](state, data) {
        try { state.hoopsData = data } catch (ex) { console.debug('set state EX', ex) }
    },
    [Const.SET_CURRENT_NEWS](state, data) {
        try { state.newsData = data } catch (ex) { console.debug('set state EX', ex) }
    },
    [Const.SET_CRYPTO_NEWS](state, data) {
        try { state.cryptoNewsData = data } catch (ex) { console.debug('set state EX', ex) }
    },
}