<template>
  <!-- <HeaderWithoutLogin v-if="!store.user.access_token" />
  <Header v-else /> -->
  <div id="main-wrapper" class="mainWrapper pb-0">
    <AppLoader v-if="store.loading" />
    <router-view />
  </div>
  <!-- <Footer /> -->
</template>

<script>
// import AppLoader from "@/components/shared/AppLoader";
import { myStore } from "@/store/pinia-store";
//import moment from "moment"
//import PriceFeed from "@/store/stomp";
export default {
  setup() {
        const store = myStore();
        return { store };
    },
  data() {
    return {
    };
  },
  
  
};
</script>

