import axios from 'axios'
import { myStore } from '@/store/pinia-store'
import router from '@/router'
import static_vars from '../staticVars.js'

const handleUnauthorizedToken = () => {
    const store = myStore()
    store.$reset()
    router.replace('/login')
}


export const post = (url, params,token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) {//for login api 
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        headers['Content-Type'] = (token && token == 'basic') ? 'application/x-www-form-urlencoded' : 'application/json'
        headers['Accept'] = 'application/json'
        headers['Accept-Encoding'] = 'gzip, deflate, br'
        axios.post(
            url,
            params,
            { headers }
        ).then(response => {
            if (response.data.status && response.data.status === 404) {
                
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.status && error.status === 401) {
                handleUnauthorizedToken()
                reject(error)
            } else {
                console.log('error',error)
                
                reject(error)
            }
        })
    })
}

export const get = (url) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        headers['Accept-Encoding'] = 'gzip, deflate, br'
        axios.get(
            url,
            { headers }
        ).then(response => {
            if (response.info && response.info == 'NOK') {
                
                reject(response)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.response && error.response.status && error.response.status === 401) {
                handleUnauthorizedToken()
                reject(error)
            } else {
                
                reject({ ...error })
            }
        })
    })
}

export const DELETE = (url,params) => {
    try{
        return new Promise((resolve, reject) => {
            const store = myStore()
            const headers = {}
            if (store.user.access_token) {
                headers['Authorization'] = `Bearer ${store.user.access_token}`
            }
            headers['Content-Type'] = 'application/json'
            headers['Accept'] = 'application/json'
            headers['Accept-Encoding'] = 'gzip, deflate, br'
            axios.delete(url,{
                data : params,
                headers 
            }).then(response => {
                if (response.data.status && response.data.status === 404) {
                    
                    reject(response.data)
                } else {
                    resolve(response.data)
                }
            }).catch(error => {
                if (error.status && error.status === 401) {
                    handleUnauthorizedToken()
                    reject(error)
                } else {
                    
                    reject(error)
                }
            })
        })
    }catch(e){
        console.log('e',e)
    }
}

export const put = (url, params,token) => {
    return new Promise((resolve, reject) => {
        const store = myStore()
        const headers = {}
        if (store.user.access_token) {
            headers['Authorization'] = `Bearer ${store.user.access_token}`
        }
        if (token) {//for login api 
            headers['Authorization'] = token == 'basic' ? `Basic ${Buffer.from(`${static_vars.CLIENT_ID}:${static_vars.CLIENT_SECRET}`).toString('base64')}` : `Bearer ${store.user.access_token}`
        }
        headers['Content-Type'] = (token && token == 'basic') ? 'application/x-www-form-urlencoded' : 'application/json'
        headers['Accept'] = 'application/json'
        headers['Accept-Encoding'] = 'gzip, deflate, br'
        axios.put(
            url,
            params,
            { headers }
        ).then(response => {
            if (response.data.status && response.data.status === 404) {
                
                reject(response.data)
            } else {
                resolve(response.data)
            }
        }).catch(error => {
            if (error.status && error.status === 401) {
                handleUnauthorizedToken()
                reject(error)
            } else {
                
                reject(error)
            }
        })
    })
}