<template>
  <div class="loaderOverlay">
    <!-- <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div> -->
      <img src="/assets/images/spinner.svg" alt="zulu">
  </div>
</template>
