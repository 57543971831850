export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL
export const AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL

//===========================================store function api=========================================
export const EP_USER_LOGIN = `${AUTH_BASE_URL}auth-server/oauth/token`
export const EP_GET_TRADER_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/providers/{{TradeID}}/thi/init?accessingFlavorId=1&flavor=global`
export const EP_GET_TRADER_DETAIL_SECURED = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/user/providers/{{TradeID}}/thi/init?accessingFlavorId=1&flavor=global`
export const EP_GET_TOP_TRADER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/providers/performance/topTraders/search?flavorId=1&accessingFlavorId=1`
export const EP_GET_FILTERED_ALL_TRADER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/providers/performance/topTraders/{{ID}}/search?flavorId=1&accessingFlavorId=1`
export const EP_GET_SEARCH_TRADER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/search?accessingFlavorId=1&limit=5&name={{name}}&flavor=global`
export const EP_GET_COMBOS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/providers/performance/topCombos/search?flavorId=1`
export const EP_GET_MARKETS_SEARCH = `${GLOBAL_BASE_URL}/statsservice/api/markets/search`
export const EP_GET_NEWSFEED_LIST = `${GLOBAL_BASE_URL}/api/user/newsfeed`
export const EP_GET_CALCULATE_RATES = `${GLOBAL_BASE_URL}/Pages/FxTools.aspx/CalculateRates`
export const EP_GET_TOP_FIVE_FOLLOWERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{TradeID}}/top5livefollowers`
export const EP_GET_FOLLOWERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{TradeID}}/livefollowers?page={{page}}&size=10`
export const EP_GET_TRADE_HISTORY_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/providers/{{TradeID}}/trades/history?timeframe={{timeframe}}&page={{page}}&size={{size}}&sort={{sort}}`
export const EP_GET_TRADE_HISTORY_CURRENCY_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/providers/{{TradeID}}/trades/history?timeframe={{timeframe}}&page={{page}}&size={{size}}&sort={{sort}}&id={{id}}`
export const EP_GET_OPEN_POSITION_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/providers/{{TradeID}}/trades/open/all`
export const EP_GET_CALCULATE_PIP = `${GLOBAL_BASE_URL}/Pages/FxTools.aspx/CalculatePips`
export const EP_GET_CALCULATE_MARGIN = `${GLOBAL_BASE_URL}/Pages/FxTools.aspx/CalculateMargin`
export const EP_GET_CALCULATE_PROFIT = `${GLOBAL_BASE_URL}/Pages/FxTools.aspx/CalculateProfit`
export const EP_GET_CONVERT_CURRENCY = `${GLOBAL_BASE_URL}/Pages/FxTools.aspx/ConvertCurrencies`
export const EP_GET_CURRENCY_PAIRS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/currencies/details?feedGroupId={{feedGroupId}}`
export const EP_GET_TRADING_PROFIT_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan=DAILY&timeframe={{timeframe}}`
export const EP_GET_TRADING_PROFIT_CHART_DATA_WITH_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan=DAILY&timeframe={{timeframe}}&cid={{cid}}`
export const EP_GET_TRADING_PERFORMANCE_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`
export const EP_GET_TRADING_PERFORMANCE_RANKING_CHART_DATA = `${GLOBAL_BASE_URL}//zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type=RANKING&timespan=&timeframe={{timeframe}}&flavor=global`
export const EP_GET_CHART_DATA = `${GLOBAL_BASE_URL}//zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?`

/*** Social Start ***/
export const EP_GET_SOCIAL_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10`
// export const EP_GET_SOCIAL_AUTOLOAD_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}`
export const EP_GET_SOCIAL_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}`
export const EP_GET_SOCIAL_TYPE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/traders/{{TradeID}}/events?type=STATUS_UPDATE`
export const EP_GET_SOCIAL_TYPE_COMMENT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed/{{ZuluEventUD}}`
export const EP_GET_SOCIAL_COMMENT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed/{{ZuluEventUD}}`
export const EP_GET_SOCIAL_HASTAG_RIGHT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/user/{{ZuluID}}/hashtags?size=10`
export const EP_GET_SOCIAL_HASTAG_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10&hashtag={{Hastag}}`
export const EP_GET_SOCIAL_HASTAG_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}&hashtagToFilter={{Hastag}}`
// export const EP_POST_SOCIAL_TOP_COMMENT_SIMPLE = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/events/save`
export const EP_POST_SOCIAL_TOP_COMMENT = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/v2/api/events/save`
export const EP_GET_SOCIAL_TOP_COMMENT_PUBLIC_NAME = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/user/search?type=PUBLIC_NAME&searchTerm={{publicName}}`
export const EP_GET_SOCIAL_TOP_COMMENT_HASHTAG = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/user/search?type=HASHTAG&searchTerm={{hastagName}}`
export const EP_GET_SOCIAL_LIVE_FOLLOWERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{TradeID}}/livefollowers?page={{page}}&size=10`
export const EP_GET_SOCIAL_FEED_PAGE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10`
export const EP_GET_SOCIAL_FEED_PAGE_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10&eventId={{EventID}}`

export const EP_GET_SOCIAL_FEED_PAGE_HASTAG_RIGHT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/hashtags?size=10`
export const EP_GET_SOCIAL_FEED_PAGE_HASTAG_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10&eventId={{EventID}}&hashtag={{Hastag}}`
export const EP_GET_SOCIAL_LIKE = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/newsfeed/{{SocialEventID}}/{{status}}`
export const EP_GET_SOCIAL_DELETE = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/newsfeed/{{SocialEventID}}/comment`
export const EP_POST_SOCIAL_COMMENT = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/v2/api/newsfeed/{{SocialEventID}}/comment`
export const EP_GET_SOCIAL_FEED_MY_HASTAGS = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/user/hashtags?size=100`
export const EP_GET_SOCIAL_FEED_HASTAG_FOLLOW = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/user/hashtags/{{hastagID}}`
/*** Social End ***/

export const EP_GET_TRADING_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`  
export const EP_GET_TRADING_DRAWDOWN_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}` 
export const EP_GET_TRADING_SLIPPAGE_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type=SLIPPAGE`  
export const EP_GET_FOLLWERS_FOLLOWING_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/brokerAccounts/{{TradeID}}/providers/following?page=0&size=2000` 
export const EP_GET_FOLLWERS_HAS_FOLLOWED_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/brokerAccounts/{{TradeID}}/providers/hasFollowed?page=0&size=2000` 
export const EP_GET_FOLLWERS_CHART_DATA = `${GLOBAL_BASE_URL}/webservices/charts.ashx?culture=en&p={{TradeID}}&b={{BrokerId}}&t=360&f=pips&g=tp`  
export const EP_GET_TRADER_COMPARE_PERMORMANCE_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/providers/performance/search`  
export const EP_GET_FOLLOWERS_COMPARE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/search` 
export const EP_GET_ALL_TRADERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/providers/performance/search` 
export const EP_GET_COUNTRIES_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/api/providers/performance/countries?flavor=global`  
export const EP_GET_CURRENCIES_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/performance/currencies?flavor=global` 
export const EP_ADD_TRADER_CUSTOM_VIEW = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/zuluAccounts/performanceUserViews`   
export const EP_GET_CUSTOM_VIEW_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/zuluAccounts/performanceFilters`
export const SOCIAL_REPORT = `${GLOBAL_BASE_URL}/zulutrade-client/translators/api/translate` 
export const EP_GET_COUNTRY_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/countries?getOnlyEuMmber=false`   
export const EP_GET_USER_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/details`  
export const EP_UPLOAD_USER_IMAGE = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/avatar`  
export const EP_SEND_SMS_MOBILE = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/verification/sms/send`  
export const EP_VERIFY_MOBILE_OTP = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/verification/sms/validate` 
export const EP_GET_WATCHLIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer/watchlist?timeframe=7`
export const EP_ADD_TO_WATCHLIST = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/watchlist`
export const EP_GET_ALL_CURRENCY_CATEGORIES = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/api/markets/search`   

/*** Subscription Start ***/
export const EP_GET_SUBSCRIPTION_PLANS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/payments/v2/membership/availablePlans`
export const EP_GET_SELECTED_PLAN = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/planDetails`
export const EP_GET_SELECT_PLAN = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/membershipPlan`
export const EP_GET_CANCEL_PLAN = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/membershipPlan`
export const EP_GET_SAVED_CARDS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/customers/instruments`
export const EP_SAVE_CARDS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/card/add`
export const EP_INITIATE_PAYMENT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/payments`
/*** Subscription End ***/

/*** Rewards Start ***/
export const EP_REWARDS_DETAILS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/rewards`
export const EP_WITHDRAWAL_HISTORY = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment`
export const EP_REWARDS_HISTORY = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment/details/breakdown`
export const EP_WITHDRAWAL_FUNDS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment/info`
export const EP_REQUEST_PAYMENT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment`
export const EP_EXISTING_PAYMENT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/affiliates/payment/info`
/*** Rewards End ***/

/*** Trading Accounts Start ***/
export const EP_BECOME_LEADER = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/leader`
/*** Trading Accounts End ***/

/*** Dashboad portfolio Start ***/
export const EP_PROVIDER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v3/api/user/providers/following/settings`
export const EP_PROVIDER_OPEN_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/open?targetBrokerAccountId={{ID}}&calculateProfit=true&page={{page}}&size={{size}}`
export const EP_PROVIDER_PENDING_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/orders?targetBrokerAccountId={{ID}}&page={{page}}&size={{size}}`
export const EP_PROVIDER_HISTORY_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history?targetBrokerAccountId={{ID}}&page={{page}}&size={{size}}`
export const EP_OPEN_TRADE_DETAILS= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/{{BROKER_TICKET}}/open/details`
export const EP_CLOSED_TRADE_DETAILS= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/{{BROKER_TICKET}}/history/details`
export const EP_PENDING_ORDER_DETAILS= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/{{BROKER_TICKET}}/orders/details`
/*** Dashboad portfolio End ***/

export const EP_GET_STRATEGY_WISE_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/api/markets/search`
export const EP_GET_ASSET_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/open?timeframe=10000&calculateProfit=true&cid={{cid}}`
export const EP_GET_ASSET_NEWS = `https://betapi.portfolios.com/get/newsby/symbol`
export const EP_GET_ASSET_PENDING_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/orders?timeframe=10000&calculateProfit=true&cid={{cid}}`
export const EP_GET_ASSET_CLOSED_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history?timeframe=10000&calculateProfit=true&cid={{cid}}`
export const EP_GET_CURRENCY_WITH_ID = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/currencies/names`
export const EP_GET_CUSTOMER_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer`
export const EP_GET_CONVESRTION_STATS = `${GLOBAL_BASE_URL}/zulutrade-client/api/trading/conversion?targetBrokerAccountId={{ID}}`
export const EP_ASSET_PLACE_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/open/market?targetBrokerAccountId={{ID}}`
export const EP_ASSET_PENDING_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/open/pending?targetBrokerAccountId={{ID}}`
export const EP_CLOSE_OPEN_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/{{ID}}/closeMarket`
export const EP_GET_TRADER_COPY_STATS = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v3/api/user/providers/following/settings?targetZuluAccountId={{ID}}`
export const EP_COPY_LEADER = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v2/api/user/follow/provider?targetZuluAccountId={{ID}}`
export const EP_UPDATE_COPY_STATEGY = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v2/api/user/follow/provider/update?targetZuluAccountId={{ID}}`
export const EP_DELETE_COPY_STATEGY = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v2/api/user/unfollow/provider/{{LEADER_ID}}?targetZuluAccountId={{ID}}&closeAllTrades=true`
export const EP_GET_MARKET_CLOSE_DAYS = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/currency/tradingSession?currencyName={{ASSET}}`
export const EP_ASSET_SEARCH_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/api/markets/search`
export const EP_GET_USER_DASHBOARD_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/followers/performance/snapshot?`
