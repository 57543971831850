export class Const {

	public static GET_TOP_GAINERS:string  = '/GET_TOP_GAINERS'
	public static SET_TOP_GAINERS:string  = '/SET_TOP_GAINERS'

	public static RUN_GET_CHART_DATA:string  = '/RUN_GET_CHART_DATA'
	public static RUN_CATEGORIES_LISTING:string  = '/RUN_CATEGORIES_LISTING'


	public static RUN_GET_FAQS_LIST:string  = '/RUN_GET_FAQS_LIST'
	public static GET_FAQS_LIST:string  = '/GET_FAQS_LIST'
	public static SET_FAQS_LIST:string  = '/SET_FAQS_LIST'

	public static RUN_GET_HOOPS_DATA:string  = '/RUN_GET_HOOPS_DATA'
	public static GET_HOOPS_DATA:string  = '/GET_HOOPS_DATA'
	public static SET_HOOPS_DATA:string  = '/SET_HOOPS_DATA'

	public static RUN_GET_CURRENT_NEWS:string  = '/RUN_GET_CURRENT_NEWS'
	public static GET_CURRENT_NEWS:string  = '/GET_CURRENT_NEWS'
	public static SET_CURRENT_NEWS:string  = '/SET_CURRENT_NEWS'

	public static RUN_GET_CRYPTO_NEWS:string  = '/RUN_GET_CRYPTO_NEWS'
	public static GET_CRYPTO_NEWS:string  = '/GET_CRYPTO_NEWS'
	public static SET_CRYPTO_NEWS:string  = '/SET_CRYPTO_NEWS'

	public static RUN_CONTACT_US:string  = '/RUN_CONTACT_US'

	
}