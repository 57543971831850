<template>
  <router-view />
</template>

<script>
//import { ProviderCacheMixins } from './plugins/vue-provider-cache/mixin.ts'
import { commonAllmixins } from './plugins/commonAll'
//import { Const } from '@/plugins/vue-provider-cache/const.mod'
//import crypto_list from '../public/assets/js/crypto-list.json'
// import seoData from '../public/assets/js/seo.js'
export default {
  mixins: [commonAllmixins],
  methods:{
    // subscribe(){
    //   let subscribeArray = crypto_list.currencies
    //   this.$store.getters[Const.GET_PROVIDERS]['priceFeed']
    //     .seeders['S_PF_PF'].subscribe(subscribeArray)
    // },
  },
  // watch:{
  //   '$route'(){
  //     if(this.$route.path){
  //       let seoJson = {}
  //       seoJson = seoData[0]
  //       let key = this.$route.path.split('/')[1]
  //       let getSeoPath = seoJson[key]
  //       if(key == undefined || seoJson[key] == undefined ){
  //         getSeoPath = seoJson[""]
  //       }
  //       if(getSeoPath){
  //           document.title = getSeoPath.META_TITLE;
  //           document.querySelector('meta[name="description"]').setAttribute("content", getSeoPath.META_DESCRIPTION);
  //           document.querySelector('meta[name="keywords"]').setAttribute("content", getSeoPath.META_KEYWORDS);
  //         /*this.pageTitle = getSeoPath.META_TITLE
  //         this.pageDescription = getSeoPath.META_DESCRIPTION
  //         this.pageKeywords = getSeoPath.META_KEYWORDS*/
  //       } 
  //     }
  //   }
  // },
  created(){
    // var _that = this
    // setTimeout(function(){ _that.subscribe(); }, 2000);
  }
};
</script>

