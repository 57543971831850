import { createApp } from 'vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import Store from './store'
import Datepicker from 'vue3-date-time-picker';
import VueFeather from 'vue-feather';
import { createI18n } from 'vue-i18n'
import staticVars from './staticVars'
import VueClipboard from 'vue3-clipboard'
import VueFloatLabel from 'vue-float-label'
// import VueMeta from 'vue-meta'

import '../public/assets/css/font-awesome.min.css'
import '../public/assets/css/bootstrap.min.css'
import '../public/assets/css/style.css';
import '../public/assets/css/responsive.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'

import Nodata from './components/shared/no-data.vue'
import AppLoader from '@/components/shared/AppLoader.vue'
import Loader from '@/components/shared/loader.vue'
import Spinner from '@/components/shared/loader-load.vue'
//import slider from "vue3-slider"


//Locales
import en from '../public/assets/locales/en.js'


//Internationalization
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  warnHtmlInMessage: 'off'
})




const app = createApp(App)
const pinia = createPinia()


app.use(VueFloatLabel)
app.use(pinia)
pinia.use(piniaPluginPersistedstate)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

app.config.globalProperties.static_vars = staticVars
app.component(VueFeather.name, VueFeather);
app.component('Nodata', Nodata);
app.component('AppLoader', AppLoader);
app.component('Loader', Loader);
app.component('Spinner', Spinner);


app.use(i18n).use(Store).use(router).mount('#app')