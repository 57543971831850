import { createRouter, createWebHistory } from "vue-router";
//import { myStore } from "@/store/pinia-store.ts";

//Entry point
import Index from "@/views/index.vue";

const routes = [
    
    {
        path: "/chart/:id",
        name: "chart",
        component: () => import("@/views/trader/chart.vue"),
        requiresAuth: false,
    },
    {
        path: "/advanced/:id",
        name: "advanced",
        component: () => import("@/views/trader/advanced.vue"),
        requiresAuth: false,
    },
    {
        path: "/market/:id",
        name: "mobileMarket",
        component: () => import("@/views/markets/mobile-market/market-chart.vue"),
        requiresAuth: false,
    },
    {
        path: "/dashboard/:token",
        name: "mobileDashboard",
        component: () => import("@/views/dashboard-new/dashboard-mobile-chart.vue"),
        requiresAuth: false,
    },
    {
        path: "/",
        component: Index,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    base: process.env.NODE_ENV === "production" ? "/" : "/",
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
});

export default router;
