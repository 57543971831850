import { defineStore } from "pinia";

import * as API from "@/api/rx-factory";
import * as ENDPOINTS from "@/api/endpoints";
import moment from "moment";
const qs = require("qs");
import router from "@/router";
import static_vars from '@/staticVars'
// interface tostType {
//   [key: string]: string;
// }
// const tostTypeError: tostType = { type: "error" };
// const tostTypeSuccess: tostType = { type: "success" };
export const myStore = defineStore({
  id: "userSettingStore",
  persist: {
    key: "userSetting",
    storage: window.localStorage,
    paths: [
      "tradingProfitChart",
      "tradingPerformanceChart",
      "tradingPairsChart",
      "tradingDrawDownChart",
       "tradingSlippageChart",
       "traderDetail",
       "assetDetail",
       "userDashboardDetail"
    ],
  },
  state: () => ({
    loading: false,
    singleLoading: false,
    tradesLoading : false,
    user: {},
    tradingProfitChart: {},
    tradingPerformanceChart: {},
    tradingPairsChart: {},
    tradingDrawDownChart: {},
    tradingSlippageChart: {},
    traderDetail : {},
    assetDetail : {},
    userDashboardDetail : {},
 
  }),
  getters: {},
  actions: {
    getAssetDetail(params = {}, loader = false,_that,targetBroker) {
      if (loader) {
        this.singleLoading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_ALL_CURRENCY_CATEGORIES
        if(targetBroker){
          ep = ep.concat(`?targetBrokerAccountId=${targetBroker}`);
       }
        API.post(ep, params)
          .then((response) => {
            this.singleLoading = false;
            if(Object.values(response).length){                                                                                              
              this.assetDetail = Object.values(response)[0]
            }else{
               this.assetDetail = response
            }
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
    calltraderDetail(params = {}, loader = false, id,_that) {
      if (loader) {
        if(_that.$route.name=='dashboard_portfolio'){ this.singleLoading = true; } else { this.loading = true; }
      }
      return new Promise((resolve, reject) => {
        let ep = (!this.user.access_token) ? ENDPOINTS.EP_GET_TRADER_DETAIL : ENDPOINTS.EP_GET_TRADER_DETAIL_SECURED;
        ep = ep.replace("{{TradeID}}", id);
        API.get(ep)
          .then((response) => {
            if(_that.$route.name=='dashboard_portfolio'){ this.singleLoading = false; } else { this.loading = false; }
            this.traderDetail = response;
            resolve(response);
          })
          .catch((error) => {
            if(_that.$route.name=='dashboard_portfolio'){ this.singleLoading = false; } else { this.loading = false; }
            reject(error);
          });
      });
    },
     callTradingProfitChartData(params = {}, loader = false, id, timeframe, type) {
      if (loader) {
        this.singleLoading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = "";
        if (params["cid"] && params["cid"].length) {
          ep = ENDPOINTS.EP_GET_TRADING_PROFIT_CHART_DATA_WITH_CURRENCY;
          ep = ep.replace("{{cid}}", params["cid"]);
        } else if (params["cid"] && params["openTrades"] && params["openTrades"] == 1) {
          ep = ENDPOINTS.EP_GET_TRADING_PROFIT_CHART_DATA;
        } else {
          ep = ENDPOINTS.EP_GET_TRADING_PROFIT_CHART_DATA;
        }
        ep = ep.replace("{{TradeID}}", id);
        ep = ep.replace("{{timeframe}}", timeframe);

        // if (type == this.traderDetail.trader.stats.profile.baseCurrencyName) {
        //   if (params["openTrades"] == 1) {
        //     ep = ep.replace("{{type}}", "TOTAL_PROFIT_MAX_OPEN_TRADES_MONEY");
        //   } else {
        //     ep = ep.replace("{{type}}", "TOTAL_PROFIT_MONEY");
        //   }
        // } else if (type == "Pips") {
        //   if (params["openTrades"] == 1) {
        //     ep = ep.replace("{{type}}", "TOTAL_PROFIT_MAX_OPEN_TRADES");
        //   } else {
        //     ep = ep.replace("{{type}}", "TOTAL_PROFIT");
        //   }
        // } else if (type == "ROI") {
        //   ep = ep.replace("{{type}}", "TOTAL_PROFIT_ROI");
        // }

        ep = ep.replace("{{type}}", "TOTAL_PROFIT_ROI");
        
        API.get(ep)
          .then((response) => {
            this.singleLoading = false;
            this.tradingProfitChart = response;
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
    callTradingPerformanceChartData(params = {}, loader = false, id, timeframe, type) {
      if (loader) {
        this.singleLoading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = "";
        if (type == "RANKING") {
          ep = ENDPOINTS.EP_GET_TRADING_PERFORMANCE_RANKING_CHART_DATA;
        } else {
          ep = ENDPOINTS.EP_GET_TRADING_PERFORMANCE_CHART_DATA;
          ep = ep.replace("{{type}}", type);
        }
        ep = ep.replace("{{TradeID}}", id);
        if (type == "BEST_TRADE,WORST_TRADE") {
          ep = ep.replace("{{timespan}}", "DAILY");
        } else if (type == "CURRENCY_CLOSED_PROFIT_MONEY" || type == "CURRENCY_CLOSED_PROFIT") {
          ep = ep.replace("{{timespan}}", "STATS");
        } else {
          ep = ep.replace("{{timespan}}", "MONTHLY");
        }
        ep = ep.replace("{{timeframe}}", timeframe);
        API.get(ep)
          .then((response) => {
            this.singleLoading = false;
            this.tradingPerformanceChart = response;
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
    callTradingPairsChartData(params = {}, loader = false, id, timeframe, type) {
      if (loader) {
        this.singleLoading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_TRADING_CHART_DATA;
        ep = ep.replace("{{type}}", type);
        ep = ep.replace("{{TradeID}}", id);
        ep = ep.replace("{{timespan}}", "STATS");
        ep = ep.replace("{{timeframe}}", timeframe);
        API.get(ep)
          .then((response) => {
            this.singleLoading = false;
            this.tradingPairsChart = response;
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
    callTrendingDrawDownChartData(params = {}, loader = false, id, timeframe, type) {
      if (loader) {
        this.singleLoading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_TRADING_DRAWDOWN_CHART_DATA;
        ep = ep.replace("{{type}}", type);
        ep = ep.replace("{{TradeID}}", id);
        ep = ep.replace("{{timespan}}", "DAILY");
        ep = ep.replace("{{timeframe}}", timeframe);
        API.get(ep)
          .then((response) => {
            this.singleLoading = false;
            if (Object.keys(response).length && response.series && response.series.length) {
              response.series.forEach((val) => {
                if (val.type == "DRAWDOWN_DAILY" || val.type == "DRAWDOWN_DAILY_MONEY") {
                  val.data.forEach((item) => {
                    item.y = Math.abs(item.y);
                  });
                }
              });
              this.tradingDrawDownChart = response;
            } else {
              this.tradingDrawDownChart = response;
            }
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
    getTrendingSlippageChartData(params = {}, loader = false, id) {
      if (loader) {
        this.singleLoading = true;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_TRADING_SLIPPAGE_CHART_DATA;
        ep = ep.replace("{{TradeID}}", id);
        API.get(ep)
          .then((response) => {
            this.singleLoading = false;
            this.tradingSlippageChart = response;
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
    getUserDashboardData(params = {}, loader = false) {
      if (loader) {
        this.singleLoading = false;
      }
      return new Promise((resolve, reject) => {
        let ep = ENDPOINTS.EP_GET_USER_DASHBOARD_DATA;
        const queries = [];
        for(const key in params){
          if(params[key]) { 
            queries.push(`${key}=${params[key]}`)
          }
        }
        // console.log('queries',queries)
        ep += queries.join('&') || '';
        API.get(ep)
          .then((response) => {
            this.singleLoading = false;
           this.userDashboardDetail = response
            resolve(response);
          })
          .catch((error) => {
            this.singleLoading = false;
            reject(error);
          });
      });
    },
  },
});
