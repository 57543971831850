import { Const } from '../lib/const.cls'

export const getters = {
    [Const.GET_TOP_GAINERS]: (state) => { return state.topGainers },
    [Const.GET_FAQS_LIST]: (state) => { return state.faqslist },
    [Const.GET_HOOPS_DATA]: (state) => { return state.hoopsData },
    [Const.GET_CURRENT_NEWS]: (state) => { return state.newsData },
    [Const.GET_CRYPTO_NEWS]: (state) => { return state.cryptoNewsData },
    // GET_CATEGORIES_LISTING
    // [Const.GET_CATEGORIES_LISTING]: (state) => {
    //     return state.CATEGORIES
    // },
    // GET_ARTICELS_LISTING
    // [Const.GET_ARTICELS_LISTING]: (state) => {
    //     return state.ARTICELS
    // }
}