export default {
    imagesURL: 'https://www.zulutrade.com/',
    domainURL: 'https://dev1.zulutrade.com/',
    CLIENT_ID: 'm$cL1EnT1d',
    CLIENT_SECRET: 'm$cL1EnT1pR0@dS3Cr3t',
    CHECKOUT_PUBLIC_API_KEY: 'pk_sbox_jwvr24ls3dgmosm5hf2kwgd6qam',
    marketImageSURL : 'https://finvasiaexpos.s3.eu-west-2.amazonaws.com/tradingsymbol/',
    limitPerPage: 10,
    setCookie:(cname,cvalue,exdays)=>{
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires=" + ((cvalue)?d.toUTCString():new Date());
      document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
    },
    getCookie:(cname)=>{
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },
    delete_cookie:(name) => {
      document.cookie = name +'=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    registrationType: [
        { label: "Individual", value: "0" },
        { label: "Corporate", value: "1" }
    ],
    leverages: [
        { label: "100X", value: "100" },
        { label: "200X", value: "200" },
        { label: "300X", value: "300" },
        { label: "400X", value: "400" },
        { label: "500X", value: "500" }
    ],
    accountTypes: [
        { label: 'ECN', value: 'ECN' },
        { label: 'ECN PRO', value: 'ECNP' },
        { label: 'Classic', value: 'CLASSIC' },
        { label: 'Profit Sharing', value: 'PROFIT_SHARING' }
    ],
    platforms: [
        { label: 'MetaTrader 4', value: 'MT4' },
        { label: 'MetaTrader 5', value: 'MT5' },
        { label: 'Zulu Trading Platform', value: 'ZTP' }
    ],
    currency: {
        'USD': '$',
        'GBP': '£',
        'EUR': '€',
    },
    //METHODS FROM FXVIEW
    // depositMethod: { '1': '"Bank"', '2': '"Skrill"', '3': '"Neteller"', '4': '"Trading Account"', '5': '"Debit/Credit card"', '6': '"Giropay"', '7': '"Przelewy"', '8': '"Rapid Transfer"', '9': '"Nordea"', '10': '"Klarna"', '11': '"NBP"', '12': '"TR"', '13': '"CR"', '15': '"Loyalty Transfer"', '18': '"Bitcoin"', '17': '"Praxis"', '19': '"LBT-Gatech"', '20': '"LBT-Payguru"', '21': '"LBT-PayRetailer"', '22': '"LBT-Rapid OZOW"' }
    depositMethods: {
        keys: ['All', 'Bank Cards', 'Bank Wire', 'E-Wallet', 'Cryptos'],
        methods: [
            {
                type: 'Bank Cards',
                minimumDeposit: 100,
                methodID: 5,
                name: 'Credit Cards',
                image: 'dep1.png',
                region: 'World Wide',
                currencies: 'EUR, USD, CHF, GBP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 2,
                name: 'Eurobank',
                image: 'dep2.png',
                region: 'World Wide',
                currencies: 'USD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 3,
                name: 'Dixipay',
                image: 'dep3.png',
                region: 'Europe',
                currencies: 'EUR (Sepa Only)',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 4,
                name: 'Help 2 Pay',
                image: 'dep4.png',
                region: 'South East Asia',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Bank Wire',
                minimumDeposit: 100,
                methodID: 5,
                name: 'Pay Retailers',
                image: 'dep5.png',
                region: 'Brazil, Mexico, Chile',
                currencies: 'BRL, MXN, CLP',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 6,
                name: 'Virtual Pay',
                image: 'dep6.png',
                region: 'East Africa',
                currencies: 'TAS, KES, MTN',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 2,
                name: 'Skrill',
                image: 'dep7.png',
                region: 'World Wide',
                currencies: 'EUR, USD, JPY, CHF, GBP, AUD',
                processingTime: '2-3 business days',
                action: ''
            },
            {
                type: 'E-Wallet',
                minimumDeposit: 100,
                methodID: 3,
                name: 'Neteller',
                image: 'dep8.png',
                region: 'World Wide',
                currencies: 'MY, TH, ID, PH, VN',
                processingTime: 'Instant',
                action: ''
            },
            {
                type: 'Cryptos',
                minimumDeposit: 100,
                methodID: 9,
                name: 'Bitcoin',
                image: 'bitcoin-ico.png',
                region: 'World Wide',
                currencies: 'BTC',
                processingTime: '30 mins',
                action: ''
            },
        ]
    },
    timezones: [
        { value: "-12:00", label: '(GMT -12:00) Eniwetok, Kwajalein' },
        { value: "-11:00", label: '(GMT -11:00) Midway Island, Samoa' },
        { value: "-10:00", label: '(GMT -10:00) Hawaii' },
        { value: "-09:50", label: '(GMT -9:30) Taiohae' },
        { value: "-09:00", label: '(GMT -9:00) Alaska' },
        { value: "-08:00", label: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
        { value: "-07:00", label: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
        { value: "-06:00", label: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
        { value: "-05:00", label: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
        { value: "-04:50", label: '(GMT -4:30) Caracas' },
        { value: "-04:00", label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
        { value: "-03:50", label: '(GMT -3:30) Newfoundland' },
        { value: "-03:00", label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
        { value: "-02:00", label: '(GMT -2:00) Mid-Atlantic' },
        { value: "-01:00", label: '(GMT -1:00) Azores, Cape Verde Islands' },
        { value: "+00:00", label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
        { value: "+01:00", label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
        { value: "+02:00", label: '(GMT +2:00) Kaliningrad, South Africa' },
        { value: "+03:00", label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
        { value: "+03:50", label: '(GMT +3:30) Tehran' },
        { value: "+04:00", label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
        { value: "+04:50", label: '(GMT +4:30) Kabul' },
        { value: "+05:00", label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
        { value: "+05:50", label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
        { value: "+05:75", label: '(GMT +5:45) Kathmandu, Pokhara' },
        { value: "+06:00", label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
        { value: "+06:50", label: '(GMT +6:30) Yangon, Mandalay' },
        { value: "+07:00", label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
        { value: "+08:00", label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
        { value: "+08:75", label: '(GMT +8:45) Eucla' },
        { value: "+09:00", label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
        { value: "+09:50", label: '(GMT +9:30) Adelaide, Darwin' },
        { value: "+10:00", label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
        { value: "+10:50", label: '(GMT +10:30) Lord Howe Island' },
        { value: "+11:00", label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
        { value: "+11:50", label: '(GMT +11:30) Norfolk Island' },
        { value: "+12:00", label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
        { value: "+12:75", label: '(GMT +12:45) Chatham Islands' },
        { value: "+13:00", label: '(GMT +13:00) Apia, Nukualofa' },
        { value: "+14:00", label: '(GMT +14:00) Line Islands, Tokelau' },

    ],
    currencyList : [
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 520,
            "Name": "AAL",
            "Buy": 14.05,
            "Sell": 14.04,
            "Updated": "/Date(1668805188414)/",
            "LastUpdated": "2022-11-18 20:59:48",
            "Spread": 0.10000000000001563,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 522,
            "Name": "AAPL",
            "Buy": 151.19,
            "Sell": 151.16,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.30000000000001137,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 763,
            "Name": "ABNB",
            "Buy": 97.82,
            "Sell": 97.79,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.29999999999986926,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 675,
            "Name": "ACN",
            "Buy": 286.7,
            "Sell": 286.36,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 3.39999999999975,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 514,
            "Name": "ADBE",
            "Buy": 330.87,
            "Sell": 330.77,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.0000000000002274,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 870,
            "Name": "ALB",
            "Buy": 276.97,
            "Sell": 276.71,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 26.000000000004775,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 913,
            "Name": "ALPHA",
            "Buy": 0.9482,
            "Sell": 0.9452,
            "Updated": "/Date(1668783593578)/",
            "LastUpdated": "2022-11-18 14:59:53",
            "Spread": 0.030000000000000027,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 515,
            "Name": "AMD",
            "Buy": 73.59,
            "Sell": 73.57,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.20000000000010232,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 519,
            "Name": "AMGN",
            "Buy": 287.42,
            "Sell": 287.11,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 3.1000000000000227,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 518,
            "Name": "AMZN",
            "Buy": 94.16,
            "Sell": 94.14,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 685,
            "Name": "AON",
            "Buy": 301.51,
            "Sell": 301.28,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 2.300000000000182,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 871,
            "Name": "APA",
            "Buy": 46.34,
            "Sell": 46.32,
            "Updated": "/Date(1668805196210)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 2.0000000000003126,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 524,
            "Name": "ASML",
            "Buy": 593.49,
            "Sell": 593.25,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 2.400000000000091,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 513,
            "Name": "ATVI",
            "Buy": 74.06,
            "Sell": 74.04,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 16,
            "Name": "AUD/CAD",
            "Buy": 0.88913,
            "Sell": 0.88902,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.0999999999994348,
            "BrokerId": 0,
            "PipCost": 7.4497329,
            "InterestBuy": -2.32,
            "InterestSell": 0.84,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 28,
            "Name": "AUD/CHF",
            "Buy": 0.63462,
            "Sell": 0.63456,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 0.5999999999994898,
            "BrokerId": 0,
            "PipCost": 10.4364524,
            "InterestBuy": 3.15,
            "InterestSell": -7.31,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 17,
            "Name": "AUD/JPY",
            "Buy": 94.053,
            "Sell": 94.046,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.6999999999990791,
            "BrokerId": 0,
            "PipCost": 7.0441386,
            "InterestBuy": 16.95,
            "InterestSell": -37.1,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 21,
            "Name": "AUD/NZD",
            "Buy": 1.08269,
            "Sell": 1.08253,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.5999999999993797,
            "BrokerId": 0,
            "PipCost": 6.11915,
            "InterestBuy": -1,
            "InterestSell": 0.22,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 8,
            "Name": "AUD/USD",
            "Buy": 0.66247,
            "Sell": 0.66242,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 0.49999999999994493,
            "BrokerId": 0,
            "PipCost": 10,
            "InterestBuy": -3.08,
            "InterestSell": 0.97,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 141,
            "Name": "AUS200",
            "Buy": 7149.18,
            "Sell": 7144.98,
            "Updated": "/Date(1669033975913)/",
            "LastUpdated": "2022-11-21 12:32:55",
            "Spread": 4.200000000000728,
            "BrokerId": 0,
            "PipCost": 0.66238,
            "InterestBuy": -79269.41444,
            "InterestSell": 0,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 872,
            "Name": "AUY",
            "Buy": 5.06,
            "Sell": 5.05,
            "Updated": "/Date(1668805168018)/",
            "LastUpdated": "2022-11-18 20:59:28",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 527,
            "Name": "AVGO",
            "Buy": 515.41,
            "Sell": 514.87,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 5.399999999999636,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 616,
            "Name": "AXP",
            "Buy": 152.53,
            "Sell": 152.44,
            "Updated": "/Date(1668805194724)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 0.9000000000000341,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 617,
            "Name": "BA",
            "Buy": 173.95,
            "Sell": 173.86,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.8999999999997499,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 706,
            "Name": "BABA",
            "Buy": 80.53,
            "Sell": 80.52,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 1.0000000000005116,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 645,
            "Name": "BAC",
            "Buy": 37.19,
            "Sell": 37.18,
            "Updated": "/Date(1668805175226)/",
            "LastUpdated": "2022-11-18 20:59:35",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 873,
            "Name": "BB",
            "Buy": 4.75,
            "Sell": 4.74,
            "Updated": "/Date(1668804624408)/",
            "LastUpdated": "2022-11-18 20:50:24",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 874,
            "Name": "BBBY",
            "Buy": 3.38,
            "Sell": 3.37,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 295,
            "Name": "BCHUSD",
            "Buy": 102.56,
            "Sell": 101.79,
            "Updated": "/Date(1669033978063)/",
            "LastUpdated": "2022-11-21 12:32:58",
            "Spread": 0.769999999999996,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 914,
            "Name": "BELA",
            "Buy": 15,
            "Sell": 15,
            "Updated": "/Date(1668783589057)/",
            "LastUpdated": "2022-11-18 14:59:49",
            "Spread": 0,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 670,
            "Name": "BK",
            "Buy": 44.58,
            "Sell": 44.56,
            "Updated": "/Date(1668805194724)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 589,
            "Name": "BKNG",
            "Buy": 1940.35,
            "Sell": 1936.32,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 40.29999999999973,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 683,
            "Name": "BLK",
            "Buy": 720.96,
            "Sell": 720.43,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 5.300000000000864,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 651,
            "Name": "BMY",
            "Buy": 77.45,
            "Sell": 77.43,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 226,
            "Name": "BTCUSD",
            "Buy": 16044.4,
            "Sell": 16012.35,
            "Updated": "/Date(1669033980930)/",
            "LastUpdated": "2022-11-21 12:33:00",
            "Spread": 32.04999999999927,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 160,
            "Name": "Bund",
            "Buy": 139.94,
            "Sell": 139.91,
            "Updated": "/Date(1669033979133)/",
            "LastUpdated": "2022-11-21 12:32:59",
            "Spread": 3.0000000000001137,
            "BrokerId": 0,
            "PipCost": 1.024025,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 647,
            "Name": "C",
            "Buy": 48.68,
            "Sell": 48.67,
            "Updated": "/Date(1668805191278)/",
            "LastUpdated": "2022-11-18 20:59:51",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 61,
            "Name": "CAD/CHF",
            "Buy": 0.71402,
            "Sell": 0.71364,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 3.7999999999993594,
            "BrokerId": 0,
            "PipCost": 10.4364524,
            "InterestBuy": 4.59,
            "InterestSell": -10.66,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 18,
            "Name": "CAD/JPY",
            "Buy": 105.77,
            "Sell": 105.756,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.3999999999995794,
            "BrokerId": 0,
            "PipCost": 7.0439897,
            "InterestBuy": 24.05,
            "InterestSell": -53.6,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 618,
            "Name": "CAT",
            "Buy": 231.64,
            "Sell": 231.34,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 2.9999999999998295,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 665,
            "Name": "CCL",
            "Buy": 9.46,
            "Sell": 9.45,
            "Updated": "/Date(1668805171926)/",
            "LastUpdated": "2022-11-18 20:59:31",
            "Spread": 0.10000000000001563,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 12,
            "Name": "CHF/JPY",
            "Buy": 148.207,
            "Sell": 148.189,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.8000000000000682,
            "BrokerId": 0,
            "PipCost": 7.0441386,
            "InterestBuy": -1.05,
            "InterestSell": -5.45,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 671,
            "Name": "CL",
            "Buy": 76.48,
            "Sell": 76.46,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 0.20000000000010232,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 678,
            "Name": "CME",
            "Buy": 172.55,
            "Sell": 172.45,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.0000000000002274,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 765,
            "Name": "COIN",
            "Buy": 45.28,
            "Sell": 45.26,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.20000000000003126,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 161,
            "Name": "Copper",
            "Buy": 3.59442,
            "Sell": 3.59186,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 2.5599999999998957,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 1000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 373,
            "Name": "CORNF",
            "Buy": 665.34,
            "Sell": 664.44,
            "Updated": "/Date(1669033973150)/",
            "LastUpdated": "2022-11-21 12:32:53",
            "Spread": 8.999999999999773,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 541,
            "Name": "COST",
            "Buy": 524.26,
            "Sell": 523.75,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 5.099999999999909,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 667,
            "Name": "CRM",
            "Buy": 148.12,
            "Sell": 148.09,
            "Updated": "/Date(1668805194724)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 0.30000000000001137,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 538,
            "Name": "CSCO",
            "Buy": 47.83,
            "Sell": 47.81,
            "Updated": "/Date(1668805196210)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 619,
            "Name": "CVX",
            "Buy": 183.05,
            "Sell": 182.95,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.0000000000002274,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 875,
            "Name": "CX",
            "Buy": 4.49,
            "Sell": 4.48,
            "Updated": "/Date(1668805171629)/",
            "LastUpdated": "2022-11-18 20:59:31",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 663,
            "Name": "DAL",
            "Buy": 34.47,
            "Sell": 34.46,
            "Updated": "/Date(1668805193845)/",
            "LastUpdated": "2022-11-18 20:59:53",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 876,
            "Name": "DBX",
            "Buy": 22.29,
            "Sell": 22.28,
            "Updated": "/Date(1668805191278)/",
            "LastUpdated": "2022-11-18 20:59:51",
            "Spread": 0.999999999999801,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 877,
            "Name": "DE",
            "Buy": 414.11,
            "Sell": 413.5,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 61.000000000001364,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 640,
            "Name": "DIS",
            "Buy": 91.9,
            "Sell": 91.83,
            "Updated": "/Date(1668805189961)/",
            "LastUpdated": "2022-11-18 20:59:49",
            "Spread": 0.7000000000000739,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 545,
            "Name": "EA",
            "Buy": 130.04,
            "Sell": 129.97,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.6999999999999318,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 546,
            "Name": "EBAY",
            "Buy": 45.71,
            "Sell": 45.69,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.20000000000003126,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 878,
            "Name": "EDU",
            "Buy": 29.15,
            "Sell": 29.03,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 11.999999999999744,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 916,
            "Name": "EEEK",
            "Buy": 23.09,
            "Sell": 23.03,
            "Updated": "/Date(1668783247078)/",
            "LastUpdated": "2022-11-18 14:54:07",
            "Spread": 0.5999999999999872,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 932,
            "Name": "ELLAKTOR",
            "Buy": 1.668,
            "Sell": 1.662,
            "Updated": "/Date(1668783398861)/",
            "LastUpdated": "2022-11-18 14:56:38",
            "Spread": 0.06000000000000005,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 918,
            "Name": "ELPE",
            "Buy": 6.79,
            "Sell": 6.79,
            "Updated": "/Date(1668783588788)/",
            "LastUpdated": "2022-11-18 14:59:48",
            "Spread": 0,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 879,
            "Name": "ENPH",
            "Buy": 311.94,
            "Sell": 311.9,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 4.000000000002046,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 306,
            "Name": "EOS/USD",
            "Buy": 0.8656,
            "Sell": 0.8356,
            "Updated": "/Date(1669033947030)/",
            "LastUpdated": "2022-11-21 12:32:27",
            "Spread": 3.0000000000000027,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 136,
            "Name": "ESP35",
            "Buy": 8160.53,
            "Sell": 8152.53,
            "Updated": "/Date(1669033973764)/",
            "LastUpdated": "2022-11-21 12:32:53",
            "Spread": 8,
            "BrokerId": 0,
            "PipCost": 1.023965,
            "InterestBuy": -100000,
            "InterestSell": -30000,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 919,
            "Name": "ETE",
            "Buy": 3.705,
            "Sell": 3.698,
            "Updated": "/Date(1668783575573)/",
            "LastUpdated": "2022-11-18 14:59:35",
            "Spread": 0.07000000000000117,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 228,
            "Name": "ETHUSD",
            "Buy": 1125.41,
            "Sell": 1112.62,
            "Updated": "/Date(1669033972234)/",
            "LastUpdated": "2022-11-21 12:32:52",
            "Spread": 12.790000000000191,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 14,
            "Name": "EUR/AUD",
            "Buy": 1.54594,
            "Sell": 1.54579,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.500000000000945,
            "BrokerId": 0,
            "PipCost": 6.62445,
            "InterestBuy": -5.65,
            "InterestSell": 2.44,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 15,
            "Name": "EUR/CAD",
            "Buy": 1.37468,
            "Sell": 1.37456,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.1999999999989797,
            "BrokerId": 0,
            "PipCost": 7.4498717,
            "InterestBuy": -8.94,
            "InterestSell": 3.85,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 7,
            "Name": "EUR/CHF",
            "Buy": 0.98124,
            "Sell": 0.98119,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 0.49999999999994493,
            "BrokerId": 0,
            "PipCost": 10.4364524,
            "InterestBuy": 2.3,
            "InterestSell": -6.01,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 11,
            "Name": "EUR/GBP",
            "Buy": 0.86773,
            "Sell": 0.8677,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.2999999999997449,
            "BrokerId": 0,
            "PipCost": 11.80215,
            "InterestBuy": -6.41,
            "InterestSell": 2.64,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 1,
            "Name": "EUR/JPY",
            "Buy": 145.406,
            "Sell": 145.395,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.099999999999568,
            "BrokerId": 0,
            "PipCost": 7.0439897,
            "InterestBuy": 13.25,
            "InterestSell": -30.65,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 51,
            "Name": "EUR/NOK",
            "Buy": 10.4882,
            "Sell": 10.48549,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 27.100000000004343,
            "BrokerId": 0,
            "PipCost": 0.9764791,
            "InterestBuy": -6.05,
            "InterestSell": 1.35,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 22,
            "Name": "EUR/NZD",
            "Buy": 1.67367,
            "Sell": 1.67345,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 2.1999999999988695,
            "BrokerId": 0,
            "PipCost": 6.11915,
            "InterestBuy": -6.85,
            "InterestSell": 2.92,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 50,
            "Name": "EUR/SEK",
            "Buy": 10.9862,
            "Sell": 10.98316,
            "Updated": "/Date(1669033979133)/",
            "LastUpdated": "2022-11-21 12:32:59",
            "Spread": 30.400000000003757,
            "BrokerId": 0,
            "PipCost": 0.9322319,
            "InterestBuy": -1.27,
            "InterestSell": 0.03,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 2,
            "Name": "EUR/USD",
            "Buy": 1.0241,
            "Sell": 1.02405,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.5000000000010552,
            "BrokerId": 0,
            "PipCost": 10,
            "InterestBuy": -9.46,
            "InterestSell": 4.34,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 922,
            "Name": "EUROB",
            "Buy": 1.046,
            "Sell": 1.046,
            "Updated": "/Date(1668783570160)/",
            "LastUpdated": "2022-11-18 14:59:30",
            "Spread": 0,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 162,
            "Name": "EUSTX50",
            "Buy": 3906.64,
            "Sell": 3905.14,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.5,
            "BrokerId": 0,
            "PipCost": 1.024075,
            "InterestBuy": -50000,
            "InterestSell": -10000,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 547,
            "Name": "EXPE",
            "Buy": 98.39,
            "Sell": 98.29,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.9999999999999432,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 923,
            "Name": "EYDAP",
            "Buy": 7.04,
            "Sell": 7.02,
            "Updated": "/Date(1668783580718)/",
            "LastUpdated": "2022-11-18 14:59:40",
            "Spread": 0.20000000000000462,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 880,
            "Name": "FCEL",
            "Buy": 3.57,
            "Sell": 3.56,
            "Updated": "/Date(1668805106521)/",
            "LastUpdated": "2022-11-18 20:58:26",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 676,
            "Name": "FDX",
            "Buy": 174.83,
            "Sell": 174.75,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.800000000000125,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 143,
            "Name": "FRA40",
            "Buy": 6630.16,
            "Sell": 6628.96,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.199999999999818,
            "BrokerId": 0,
            "PipCost": 1.02408,
            "InterestBuy": -79108.64801,
            "InterestSell": -19777.162,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 881,
            "Name": "FSLR",
            "Buy": 166.6,
            "Sell": 166.5,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 9.999999999999432,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 20,
            "Name": "GBP/AUD",
            "Buy": 1.78173,
            "Sell": 1.78143,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 2.9999999999996696,
            "BrokerId": 0,
            "PipCost": 6.6245,
            "InterestBuy": 0.1,
            "InterestSell": -1.15,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 23,
            "Name": "GBP/CAD",
            "Buy": 1.58427,
            "Sell": 1.58406,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 2.100000000000435,
            "BrokerId": 0,
            "PipCost": 7.4497329,
            "InterestBuy": -3.64,
            "InterestSell": 1.07,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 13,
            "Name": "GBP/CHF",
            "Buy": 1.13086,
            "Sell": 1.13071,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.4999999999987246,
            "BrokerId": 0,
            "PipCost": 10.4369971,
            "InterestBuy": 5.85,
            "InterestSell": -13.85,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 6,
            "Name": "GBP/JPY",
            "Buy": 167.564,
            "Sell": 167.545,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.9000000000005457,
            "BrokerId": 0,
            "PipCost": 7.0441386,
            "InterestBuy": 31.15,
            "InterestSell": -70.1,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 27,
            "Name": "GBP/NZD",
            "Buy": 1.92885,
            "Sell": 1.92855,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 2.9999999999996696,
            "BrokerId": 0,
            "PipCost": 6.11915,
            "InterestBuy": -1.17,
            "InterestSell": 0.08,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 78,
            "Name": "GBP/SGD",
            "Buy": 1.63164,
            "Sell": 1.63125,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 3.8999999999989043,
            "BrokerId": 0,
            "PipCost": 7.2341522,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 3,
            "Name": "GBP/USD",
            "Buy": 1.18026,
            "Sell": 1.18017,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.9000000000014552,
            "BrokerId": 0,
            "PipCost": 10,
            "InterestBuy": -4.27,
            "InterestSell": 1.71,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 924,
            "Name": "GEKTERNA",
            "Buy": 9.65,
            "Sell": 9.62,
            "Updated": "/Date(1668783585790)/",
            "LastUpdated": "2022-11-18 14:59:45",
            "Spread": 0.30000000000001137,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 137,
            "Name": "GER30",
            "Buy": 14324.4,
            "Sell": 14323.67,
            "Updated": "/Date(1669033980930)/",
            "LastUpdated": "2022-11-21 12:33:00",
            "Spread": 0.7299999999995634,
            "BrokerId": 0,
            "PipCost": 1.024055,
            "InterestBuy": -178318.89265,
            "InterestSell": -49533.02802,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 553,
            "Name": "GILD",
            "Buy": 83.62,
            "Sell": 83.58,
            "Updated": "/Date(1668805195635)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.40000000000006253,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 643,
            "Name": "GM",
            "Buy": 39.76,
            "Sell": 39.74,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 555,
            "Name": "GOOGL",
            "Buy": 97.48,
            "Sell": 97.43,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.4999999999999716,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 882,
            "Name": "GPRO",
            "Buy": 5.49,
            "Sell": 5.47,
            "Updated": "/Date(1668804607627)/",
            "LastUpdated": "2022-11-18 20:50:07",
            "Spread": 2.000000000000046,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 622,
            "Name": "GS",
            "Buy": 379.3,
            "Sell": 379.08,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 2.200000000000273,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 664,
            "Name": "HAL",
            "Buy": 36.72,
            "Sell": 36.7,
            "Updated": "/Date(1668805195054)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 623,
            "Name": "HD",
            "Buy": 313.29,
            "Sell": 313.21,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.8000000000004093,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 797,
            "Name": "HLT",
            "Buy": 139.51,
            "Sell": 139.42,
            "Updated": "/Date(1668805189011)/",
            "LastUpdated": "2022-11-18 20:59:49",
            "Spread": 9.000000000000341,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 884,
            "Name": "HOG",
            "Buy": 47.6,
            "Sell": 47.59,
            "Updated": "/Date(1668805194724)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 0.999999999999801,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 766,
            "Name": "HOOD",
            "Buy": 9.2,
            "Sell": 9.19,
            "Updated": "/Date(1668805178550)/",
            "LastUpdated": "2022-11-18 20:59:38",
            "Spread": 0.09999999999999787,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 654,
            "Name": "HPQ",
            "Buy": 29.5,
            "Sell": 29.48,
            "Updated": "/Date(1668805194724)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 0.19999999999999574,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 925,
            "Name": "HTO",
            "Buy": 15.17,
            "Sell": 15.16,
            "Updated": "/Date(1668783529997)/",
            "LastUpdated": "2022-11-18 14:58:49",
            "Spread": 0.09999999999999787,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 885,
            "Name": "HUBS",
            "Buy": 274.67,
            "Sell": 272.71,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 196.00000000000364,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 624,
            "Name": "IBM",
            "Buy": 147.68,
            "Sell": 147.66,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.20000000000010232,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 560,
            "Name": "INTC",
            "Buy": 29.9,
            "Sell": 29.89,
            "Updated": "/Date(1668805195635)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 886,
            "Name": "JBL",
            "Buy": 69.52,
            "Sell": 69.38,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 14.000000000000057,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 565,
            "Name": "JD",
            "Buy": 56.2,
            "Sell": 55.88,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 3.200000000000003,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 625,
            "Name": "JNJ",
            "Buy": 174.99,
            "Sell": 174.99,
            "Updated": "/Date(1668965458688)/",
            "LastUpdated": "2022-11-20 17:30:58",
            "Spread": 0,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 627,
            "Name": "JPM",
            "Buy": 133.98,
            "Sell": 133.88,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.9999999999999432,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 149,
            "Name": "JPN225",
            "Buy": 27977.47,
            "Sell": 27962.27,
            "Updated": "/Date(1669033950357)/",
            "LastUpdated": "2022-11-21 12:32:30",
            "Spread": 15.200000000000728,
            "BrokerId": 0,
            "PipCost": 0.7044139,
            "InterestBuy": -1981.32092,
            "InterestSell": -990.66097,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 567,
            "Name": "KHC",
            "Buy": 38.11,
            "Sell": 38.09,
            "Updated": "/Date(1668805188414)/",
            "LastUpdated": "2022-11-18 20:59:48",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 626,
            "Name": "KO",
            "Buy": 61.19,
            "Sell": 61.17,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 926,
            "Name": "LAMDA",
            "Buy": 5.75,
            "Sell": 5.73,
            "Updated": "/Date(1668783592402)/",
            "LastUpdated": "2022-11-18 14:59:52",
            "Spread": 0.19999999999999574,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 682,
            "Name": "LMT",
            "Buy": 476.9,
            "Sell": 476.76,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 1.3999999999998636,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 227,
            "Name": "LTCUSD",
            "Buy": 59.91,
            "Sell": 59.61,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.29999999999999716,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 762,
            "Name": "LUV",
            "Buy": 38.13,
            "Sell": 38.11,
            "Updated": "/Date(1668805188414)/",
            "LastUpdated": "2022-11-18 20:59:48",
            "Spread": 0.20000000000003126,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 887,
            "Name": "LYFT",
            "Buy": 11.23,
            "Sell": 11.22,
            "Updated": "/Date(1668805129622)/",
            "LastUpdated": "2022-11-18 20:58:49",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 656,
            "Name": "M",
            "Buy": 22.24,
            "Sell": 22.23,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 672,
            "Name": "MA",
            "Buy": 344.15,
            "Sell": 343.78,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 3.7000000000000455,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 868,
            "Name": "MARA",
            "Buy": 7.46,
            "Sell": 7.45,
            "Updated": "/Date(1668804988617)/",
            "LastUpdated": "2022-11-18 20:56:28",
            "Spread": 0.09999999999999787,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 628,
            "Name": "MCD",
            "Buy": 273.64,
            "Sell": 273.49,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.4999999999997726,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 575,
            "Name": "MDLZ",
            "Buy": 65,
            "Sell": 64.99,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.10000000000005116,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 733,
            "Name": "META",
            "Buy": 112.07,
            "Sell": 112.04,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.29999999999986926,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 629,
            "Name": "MMM",
            "Buy": 126.83,
            "Sell": 126.68,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.4999999999999147,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 927,
            "Name": "MOH",
            "Buy": 17.84,
            "Sell": 17.83,
            "Updated": "/Date(1668783579479)/",
            "LastUpdated": "2022-11-18 14:59:39",
            "Spread": 0.10000000000001563,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 630,
            "Name": "MRK",
            "Buy": 104.29,
            "Sell": 104.24,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.5000000000001137,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 888,
            "Name": "MRVL",
            "Buy": 44,
            "Sell": 43.98,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 2.0000000000003126,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 652,
            "Name": "MS",
            "Buy": 89.21,
            "Sell": 89.16,
            "Updated": "/Date(1668805191278)/",
            "LastUpdated": "2022-11-18 20:59:51",
            "Spread": 0.4999999999999716,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 577,
            "Name": "MSFT",
            "Buy": 241.31,
            "Sell": 241.27,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.3999999999999204,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 889,
            "Name": "MTCH",
            "Buy": 46.39,
            "Sell": 46.33,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 6.000000000000227,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 928,
            "Name": "MYTIL",
            "Buy": 16.92,
            "Sell": 16.88,
            "Updated": "/Date(1668783587581)/",
            "LastUpdated": "2022-11-18 14:59:47",
            "Spread": 0.400000000000027,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 140,
            "Name": "NAS100",
            "Buy": 11590.16,
            "Sell": 11588.76,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.3999999999996362,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": -220000,
            "InterestSell": 30000,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 890,
            "Name": "NEE",
            "Buy": 83.36,
            "Sell": 83.26,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 9.999999999999432,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 582,
            "Name": "NFLX",
            "Buy": 287.98,
            "Sell": 287.87,
            "Updated": "/Date(1668805195635)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 1.1000000000001364,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 163,
            "Name": "NGAS",
            "Buy": 6.38678,
            "Sell": 6.37066,
            "Updated": "/Date(1669033980695)/",
            "LastUpdated": "2022-11-21 12:33:00",
            "Spread": 16.119999999999912,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 1000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 891,
            "Name": "NIO",
            "Buy": 10.49,
            "Sell": 10.48,
            "Updated": "/Date(1668805148530)/",
            "LastUpdated": "2022-11-18 20:59:08",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 631,
            "Name": "NKE",
            "Buy": 105.54,
            "Sell": 105.49,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.5000000000001137,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 892,
            "Name": "NOK",
            "Buy": 4.79,
            "Sell": 4.78,
            "Updated": "/Date(1668805182725)/",
            "LastUpdated": "2022-11-18 20:59:42",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 894,
            "Name": "NVAX",
            "Buy": 19.74,
            "Sell": 19.58,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 16.000000000000014,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 584,
            "Name": "NVDA",
            "Buy": 154.14,
            "Sell": 154.07,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.6999999999999318,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 60,
            "Name": "NZD/CAD",
            "Buy": 0.82141,
            "Sell": 0.8213,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.0999999999994348,
            "BrokerId": 0,
            "PipCost": 7.4497329,
            "InterestBuy": -1.56,
            "InterestSell": 0.41,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 62,
            "Name": "NZD/CHF",
            "Buy": 0.58653,
            "Sell": 0.58614,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 3.9000000000000146,
            "BrokerId": 0,
            "PipCost": 10.4364524,
            "InterestBuy": 3.18,
            "InterestSell": -7.53,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 19,
            "Name": "NZD/JPY",
            "Buy": 86.883,
            "Sell": 86.863,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 1.999999999999602,
            "BrokerId": 0,
            "PipCost": 7.0439897,
            "InterestBuy": 16.85,
            "InterestSell": -37.9,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 10,
            "Name": "NZD/USD",
            "Buy": 0.61194,
            "Sell": 0.61189,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 0.49999999999994493,
            "BrokerId": 0,
            "PipCost": 10,
            "InterestBuy": -2.01,
            "InterestSell": 0.75,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 929,
            "Name": "OPAP",
            "Buy": 12.67,
            "Sell": 12.66,
            "Updated": "/Date(1668783537818)/",
            "LastUpdated": "2022-11-18 14:58:57",
            "Spread": 0.09999999999999787,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 655,
            "Name": "ORCL",
            "Buy": 79.73,
            "Sell": 79.67,
            "Updated": "/Date(1668805196210)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.6000000000000227,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 591,
            "Name": "PEP",
            "Buy": 181.4,
            "Sell": 181.33,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.6999999999999318,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 632,
            "Name": "PFE",
            "Buy": 48.25,
            "Sell": 48.23,
            "Updated": "/Date(1668805196210)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.20000000000003126,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 633,
            "Name": "PG",
            "Buy": 142.6,
            "Sell": 142.58,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.1999999999998181,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 895,
            "Name": "PINS",
            "Buy": 24.35,
            "Sell": 24.34,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 1.0000000000001563,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 896,
            "Name": "PLTR",
            "Buy": 7.41,
            "Sell": 7.39,
            "Updated": "/Date(1668805194724)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 2.000000000000046,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 867,
            "Name": "PLUG",
            "Buy": 15.84,
            "Sell": 15.83,
            "Updated": "/Date(1668805183007)/",
            "LastUpdated": "2022-11-18 20:59:43",
            "Spread": 0.09999999999999787,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 669,
            "Name": "PM",
            "Buy": 96.45,
            "Sell": 96.36,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 0.9000000000000341,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 930,
            "Name": "PMEZZ",
            "Buy": 0.0462,
            "Sell": 0.0457,
            "Updated": "/Date(1668783269277)/",
            "LastUpdated": "2022-11-18 14:54:29",
            "Spread": 0.0050000000000000044,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 931,
            "Name": "PPC",
            "Buy": 6.45,
            "Sell": 6.43,
            "Updated": "/Date(1668783545584)/",
            "LastUpdated": "2022-11-18 14:59:05",
            "Spread": 0.20000000000000462,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 590,
            "Name": "PYPL",
            "Buy": 84.92,
            "Sell": 84.88,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.40000000000006253,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 592,
            "Name": "QCOM",
            "Buy": 123.92,
            "Sell": 123.81,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.0999999999999943,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 680,
            "Name": "RCL",
            "Buy": 58.75,
            "Sell": 58.55,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 2.0000000000000284,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 898,
            "Name": "RIOT",
            "Buy": 4.45,
            "Sell": 4.44,
            "Updated": "/Date(1668805176433)/",
            "LastUpdated": "2022-11-18 20:59:36",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 597,
            "Name": "SBUX",
            "Buy": 97.95,
            "Sell": 97.92,
            "Updated": "/Date(1668805195916)/",
            "LastUpdated": "2022-11-18 20:59:55",
            "Spread": 0.30000000000001137,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 899,
            "Name": "SHOP",
            "Buy": 36.61,
            "Sell": 36.59,
            "Updated": "/Date(1668805192618)/",
            "LastUpdated": "2022-11-18 20:59:52",
            "Spread": 1.999999999999602,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 900,
            "Name": "SNAP",
            "Buy": 10.54,
            "Sell": 10.53,
            "Updated": "/Date(1668805191278)/",
            "LastUpdated": "2022-11-18 20:59:51",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 901,
            "Name": "SONY",
            "Buy": 80.5,
            "Sell": 80.37,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 12.999999999999545,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 371,
            "Name": "SOYF",
            "Buy": 1426.37,
            "Sell": 1425.37,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 10,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 902,
            "Name": "SPLK",
            "Buy": 77.6,
            "Sell": 76.95,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 64.99999999999915,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 146,
            "Name": "SPX500",
            "Buy": 3947.97,
            "Sell": 3947.57,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 3.999999999996362,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": -75000,
            "InterestSell": 9000,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 642,
            "Name": "T",
            "Buy": 18.98,
            "Sell": 18.97,
            "Updated": "/Date(1668805174631)/",
            "LastUpdated": "2022-11-18 20:59:34",
            "Spread": 0.10000000000001563,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 799,
            "Name": "TEVA",
            "Buy": 8.73,
            "Sell": 8.72,
            "Updated": "/Date(1668804842820)/",
            "LastUpdated": "2022-11-18 20:54:02",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 904,
            "Name": "TM",
            "Buy": 143.17,
            "Sell": 142.8,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 36.99999999999761,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 603,
            "Name": "TMUS",
            "Buy": 148.85,
            "Sell": 148.67,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.8000000000000682,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 915,
            "Name": "TPEIR",
            "Buy": 1.41,
            "Sell": 1.4,
            "Updated": "/Date(1668783574366)/",
            "LastUpdated": "2022-11-18 14:59:34",
            "Spread": 0.10000000000000009,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 905,
            "Name": "TRIP",
            "Buy": 19.92,
            "Sell": 19.91,
            "Updated": "/Date(1668805190212)/",
            "LastUpdated": "2022-11-18 20:59:50",
            "Spread": 1.0000000000001563,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 601,
            "Name": "TSLA",
            "Buy": 180.2,
            "Sell": 180.12,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.7999999999998408,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 602,
            "Name": "TXN",
            "Buy": 175.28,
            "Sell": 175.09,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 1.8999999999999773,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 906,
            "Name": "U",
            "Buy": 34.66,
            "Sell": 34.64,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 1.999999999999602,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 605,
            "Name": "UAL",
            "Buy": 43.83,
            "Sell": 43.81,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 907,
            "Name": "UBER",
            "Buy": 28.95,
            "Sell": 28.94,
            "Updated": "/Date(1668805189961)/",
            "LastUpdated": "2022-11-18 20:59:49",
            "Spread": 0.999999999999801,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 144,
            "Name": "UK100",
            "Buy": 7392.79,
            "Sell": 7391.69,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.1000000000003638,
            "BrokerId": 0,
            "PipCost": 1.180215,
            "InterestBuy": -128785.86463,
            "InterestSell": 0,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 147,
            "Name": "UKOil",
            "Buy": 87.502,
            "Sell": 87.42,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 8.199999999999363,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 635,
            "Name": "UNH",
            "Buy": 530.59,
            "Sell": 530.02,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 5.7000000000005,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 370,
            "Name": "US2000",
            "Buy": 1843.75,
            "Sell": 1843.35,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 4.0000000000009095,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": -35000,
            "InterestSell": 4000,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 138,
            "Name": "US30",
            "Buy": 33683.01,
            "Sell": 33679.73,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 3.279999999998836,
            "BrokerId": 0,
            "PipCost": 1,
            "InterestBuy": -640000,
            "InterestSell": 80000,
            "PipMultiplier": 1,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 9,
            "Name": "USD/CAD",
            "Buy": 1.34234,
            "Sell": 1.34227,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.700000000000145,
            "BrokerId": 0,
            "PipCost": 7.4498994,
            "InterestBuy": 0.03,
            "InterestSell": -1.08,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 5,
            "Name": "USD/CHF",
            "Buy": 0.95821,
            "Sell": 0.95815,
            "Updated": "/Date(1669033980930)/",
            "LastUpdated": "2022-11-21 12:33:00",
            "Spread": 0.6000000000006,
            "BrokerId": 0,
            "PipCost": 10.4362891,
            "InterestBuy": 6.67,
            "InterestSell": -14.68,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 150,
            "Name": "USD/CNH",
            "Buy": 7.17274,
            "Sell": 7.1716,
            "Updated": "/Date(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.140000000000363,
            "BrokerId": 0,
            "PipCost": 13.9427136,
            "InterestBuy": 3.81,
            "InterestSell": -12.74,
            "PipMultiplier": 1000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 4,
            "Name": "USD/JPY",
            "Buy": 141.966,
            "Sell": 141.958,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 0.8000000000009777,
            "BrokerId": 0,
            "PipCost": 7.0441634,
            "InterestBuy": 34.4,
            "InterestSell": -73.9,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 53,
            "Name": "USD/MXN",
            "Buy": 19.4986,
            "Sell": 19.49543,
            "Updated": "/Date(1669033979133)/",
            "LastUpdated": "2022-11-21 12:32:59",
            "Spread": 31.700000000007833,
            "BrokerId": 0,
            "PipCost": 0.5128915,
            "InterestBuy": -23.83,
            "InterestSell": 10.05,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 52,
            "Name": "USD/NOK",
            "Buy": 10.24195,
            "Sell": 10.23892,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 30.29999999998978,
            "BrokerId": 0,
            "PipCost": 0.9764791,
            "InterestBuy": 1.41,
            "InterestSell": -5.27,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 48,
            "Name": "USD/SEK",
            "Buy": 10.72801,
            "Sell": 10.72454,
            "Updated": "/Date(1669033982750)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 34.70000000000084,
            "BrokerId": 0,
            "PipCost": 0.9322897,
            "InterestBuy": 3.05,
            "InterestSell": -9.94,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 54,
            "Name": "USD/ZAR",
            "Buy": 17.38104,
            "Sell": 17.37682,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 42.200000000001125,
            "BrokerId": 0,
            "PipCost": 0.5754136,
            "InterestBuy": -13.03,
            "InterestSell": 4.22,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTr    }(1669033982168)/",
            "LastUpdated": "2022-11-21 12:33:02",
            "Spread": 1.999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 478,
            "InterestSell": -2478,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 638,
            "Name": "V",
            "Buy": 210.96,
            "Sell": 210.54,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 4.200000000000159,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 908,
            "Name": "VALE",
            "Buy": 15.08,
            "Sell": 15.07,
            "Updated": "/Date(1668805174050)/",
            "LastUpdated": "2022-11-18 20:59:34",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 909,
            "Name": "VIPS",
            "Buy": 9.36,
            "Sell": 9.35,
            "Updated": "/Date(1668805189961)/",
            "LastUpdated": "2022-11-18 20:59:49",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 637,
            "Name": "VZ",
            "Buy": 38.57,
            "Sell": 38.56,
            "Updated": "/Date(1668805194127)/",
            "LastUpdated": "2022-11-18 20:59:54",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 910,
            "Name": "WB",
            "Buy": 14.49,
            "Sell": 14.35,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 14.000000000000057,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 610,
            "Name": "WDC",
            "Buy": 36.9,
            "Sell": 36.86,
            "Updated": "/Date(1668805196605)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.3999999999999915,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 646,
            "Name": "WFC",
            "Buy": 46.53,
            "Sell": 46.52,
            "Updated": "/Date(1668805196824)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.0999999999999801,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 372,
            "Name": "WHEATF",
            "Buy": 813.95,
            "Sell": 813.05,
            "Updated": "/Date(1669033973150)/",
            "LastUpdated": "2022-11-21 12:32:53",
            "Spread": 9.00000000000091,
            "BrokerId": 0,
            "PipCost": 0.1,    
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 639,
            "Name": "WMT",
            "Buy": 150.16,
            "Sell": 150.15,
            "Updated": "/Date(1668805196210)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.09999999999990905,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 869,
            "Name": "X",
            "Buy": 23.59,
            "Sell": 23.58,
            "Updated": "/Date(1668805197122)/",
            "LastUpdated": "2022-11-18 20:59:57",
            "Spread": 0.10000000000001563,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 77,
            "Name": "XAG/USD",
            "Buy": 20.696,
            "Sell": 20.663,
            "Updated": "/Date(1669033979133)/",
            "LastUpdated": "2022-11-21 12:32:59",
            "Spread": 3.300000000000125,
            "BrokerId": 0,
            "PipCost": 0.5,
            "InterestBuy": -348.43,
            "InterestSell": 117,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 76,
            "Name": "XAU/USD",
            "Buy": 1740.97,
            "Sell": 1740.67,
            "Updated": "/Date(1669033981276)/",
            "LastUpdated": "2022-11-21 12:33:01",
            "Spread": 2.9999999999995453,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": -26782.79,
            "InterestSell": 12346.1,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 374,
            "Name": "XLM/USD",
            "Buy": 0.08961,
            "Sell": 0.0885,
            "Updated": "/Date(1668983766820)/",
            "LastUpdated": "2022-11-20 22:36:06",
            "Spread": 11.099999999999998,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10000,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 621,
            "Name": "XOM",
            "Buy": 112.19,
            "Sell": 112.17,
            "Updated": "/Date(1668805196210)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.1999999999999602,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 911,
            "Name": "XPEV",
            "Buy": 7.76,
            "Sell": 7.75,
            "Updated": "/Date(1668805191278)/",
            "LastUpdated": "2022-11-18 20:59:51",
            "Spread": 0.9999999999999787,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 912,
            "Name": "YY",
            "Buy": 28.2,
            "Sell": 28.05,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 14.999999999999858,
            "BrokerId": 0,
            "PipCost": 0.01,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 93,
            "Name": "ZAR/JPY",
            "Buy": 8.193,
            "Sell": 8.141,
            "Updated": "/Date(1669033979133)/",
            "LastUpdated": "2022-11-21 12:32:59",
            "Spread": 5.19999999999996,
            "BrokerId": 0,
            "PipCost": 7.0439649,
            "InterestBuy": 3.15,
            "InterestSell": -7.3,
            "PipMultiplier": 100,
            "FeedGroupId": 19,
            "Tradable": true
        },
        {
            "__type": "ZuluTrade.BusinessObjects.Rate",
            "CurrencyId": 764,
            "Name": "ZM",
            "Buy": 81.65,
            "Sell": 81.59,
            "Updated": "/Date(1668805196621)/",
            "LastUpdated": "2022-11-18 20:59:56",
            "Spread": 0.6000000000000227,
            "BrokerId": 0,
            "PipCost": 0.1,
            "InterestBuy": 0,
            "InterestSell": 0,
            "PipMultiplier": 10,
            "FeedGroupId": 19,
            "Tradable": true
        }
    ],
    tradingPerformaceFilterJson : {
        0 : 'Include Unrealized PnL',
        1 : 'Closed Trades Only',
        2 : 'Trade Volume',
        3 : 'By Pair',
        4 : 'Best-Worst',
        5 : 'Ranking',
    },
    tradingPairsFilterJson : {
        1: 'Pairs',
        2 : 'Trading Timezone'
    }
}